import React from 'react';
import { Story } from '../../../../../dorian-shared/types/story/Story';
import { getEpisodeTitleIcons } from '../../utils';

type UnavailableAfterOptionsProps = {
  episodes: Story[]
}

export function UnavailableAfterOptions(props: UnavailableAfterOptionsProps) {
  const { episodes } = props;
  return (
    <>
      <option defaultValue="" value="">N/A</option>
      {episodes.map((episode) => {
        const episodeTitle = `${episode.title} ${getEpisodeTitleIcons(episode)}`;
        const optionText = episodeTitle.length > 50
          ? `${episode.chapter}. ${episode.title.substring(0, 50)}...`
          : `${episode.chapter}. ${episode.title}`;
        return (
          <option key={episode.uuid} value={episode.uuid}>
            {optionText}
          </option>
        );
      })}
    </>
  );
}
