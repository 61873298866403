import { getRuntimeEnv } from '../../helpers/runtimeEnv';
import { RuntimeEnv } from '../../helpers/RuntimeEnvEnum';

export interface Size {
  width: number;
  height: number;
}
export const calculateCroppedVideoSize = (originalSize: Size, desiredSize: Size): Size => {
  const originalRatio = originalSize.width / originalSize.height;

  return {
    width: Math.round(desiredSize.height * originalRatio),
    height: desiredSize.height,
  };
};

const __DEV__ = getRuntimeEnv() !== RuntimeEnv.Prod;

export const consoleLog = (...args: any[]) => {
  if (!__DEV__) {
    return;
  }
  // eslint-disable-next-line no-console
  console.log(...args);
};
export const consoleError = (...args: any[]) => {
  if (!__DEV__) {
    return;
  }
  // eslint-disable-next-line no-console
  console.error(...args);
};
export const consoleTable = (...args: any[]) => {
  if (!__DEV__) {
    return;
  }
  // eslint-disable-next-line no-console
  console.table(...args);
};
