import React from 'react';

export function VideoPreview({ url }: { url: string }) {
  return (
    <video
      src={url}
      controls
      loop={false}
      disablePictureInPicture
      playsInline
      preload="auto"
      crossOrigin="anonymous"
    >
      <track kind="captions" />
    </video>
  );
}
