export enum AchievementCheckOperatorType {
  AtLeast = 'atLeast',
  HasCompleted = 'hasCompleted',
}

export enum AchievementRewardType {
  None = 0,
  Episode = 1,
}

export interface IAchievementCheck {
  variableId: number,
  operator: AchievementCheckOperatorType | '',
  value: string | number,
}

export interface IAchievementReward {
  id?: number,
  type?: AchievementRewardType,
  contentId: string | null,
  name?: string,
  deleted?: boolean,
  quantity?: number,
  delaySec?: number,
  createdAt?: string,
  updatedAt?: string,
}

export interface IAchievementUsedInStory {
  Node: string,
  Episode: string,
  Chapter: number,
  EpisodeId: number,
  Book: string,
  BookId: number,
  Revision: number,
  AchievementId: string,
}

export interface INotification {
  id?: number,
  title: string,
  message: string,
  createdAt?: string,
  updatedAt?: string,
}

export interface IAchievementGetDTO {
  id: number | string,
  name: string,
  icon: string,
  displayName: string,
  description: string,
  order: number,
  isManual: boolean,
  check: IAchievementCheck,
  usedInStory: IAchievementUsedInStory[],
  isOwnedByUser: boolean,
  unavailableAfter: string | undefined,
  notification: INotification,
  reward: IAchievementReward | undefined,
}

export interface IAchievementPostDTO {
  name: string,
  icon: string | null,
  displayName: string,
  description: string,
  isManual: boolean,
  check: IAchievementCheck,
  reward: IAchievementReward,
  unavailableAfter: string | undefined,
  notification: INotification,
}
