import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import rateLimit from 'axios-rate-limit';
import { UsedArtBook } from '../../components/pages/Backgrounds/DeleteImageModal/types';
import { MemoryIcon } from '../../components/pages/Book/MemoryBank/memoryBankTypes';
import { Book } from '../../components/pages/BookEconomyPage/economics/bookEconomy';
import { IAchievementGetDTO, IAchievementPostDTO } from '../../dorian-shared/types/achievement/Achievement';
import { BookLocation } from '../../dorian-shared/types/bookLocation/bookLocation';
import { Branch } from '../../dorian-shared/types/branch/Branch';
import { Character } from '../../dorian-shared/types/character/Character';
import { ExpressionConfig } from '../../dorian-shared/types/character/ExpressionConfig';
import { type AudioConfig, type VideoConfig } from '../../dorian-shared/types/media/MediaConfigs';
import { IPerformanceRankDTO, IPerformanceRankPostDTO } from '../../dorian-shared/types/performanceRank/performanceRank';
import { StoryState } from '../../dorian-shared/types/story/Story';
import { MemoryIconGetResponse, MemoryIconPostRequest, MemoryIconPostResponse } from '../memoryBankService/types';

export class ApiService {
  private _rateLimitInstance: AxiosInstance;
  private _instance: AxiosInstance;

  public readonly MAX_REQUESTS_COUNT = 3;

  constructor(service: AxiosInstance) {
    this._rateLimitInstance = rateLimit(service, { maxRequests: this.MAX_REQUESTS_COUNT });
    this._instance = service;
  }

  public async fetchExpressionNames(): Promise<ExpressionConfig[]> {
    const response = await this._rateLimitInstance.get('/v1/characters/expressions');
    return response.data.expressions as ExpressionConfig[];
  }

  public async uploadCharacterFile(
    formData: FormData,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    const response = await this._rateLimitInstance.post('/v1/customcharacters', formData, config);
    return response;
  }

  public async uploadBackgroundFile(
    formData: FormData,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return await this._rateLimitInstance.post('/v1/backgrounds', formData, config);
  }

  public async fetchCharactersByBookId(bookId: number): Promise<Character[] | null> {
    const characters = await this._instance.get(`/v1/books/${bookId}/characters`);
    return characters?.data?.characters;
  }

  public async fetchAudioConfig(): Promise<AudioConfig | undefined> {
    const characters = await this._instance.get('/v1/audioconfig');
    return characters?.data?.audioConfig;
  }

  public async fetchVideoConfig(): Promise<VideoConfig | undefined> {
    const characters = await this._instance.get('/v1/videoconfig');
    return characters?.data?.videoConfig;
  }

  public async fetchMemoryIconsByBookId(bookId: number): Promise<MemoryIcon[]> {
    const response: AxiosResponse<MemoryIconGetResponse> = await this._instance.get(`/v1/books/${bookId}/memoryIcons/`);
    const memoryIcons: MemoryIcon[] = response.data.icons ?? [];
    return memoryIcons.map((memoryIcon: MemoryIcon) => ({
      id: memoryIcon.id,
      label: memoryIcon.label,
      bookId: memoryIcon.bookId,
      key: memoryIcon.key,
      userId: memoryIcon.userId,
      type: memoryIcon.type,
      url: memoryIcon.url,
    }));
  }

  public async createMemoryIconByUserId(
    userId: number,
    memoryIconPostRequest: MemoryIconPostRequest,
  ): Promise<MemoryIcon> {
    const memoryIconFormData = new FormData();
    memoryIconFormData.append('image', memoryIconPostRequest.image);
    memoryIconFormData.append('label', memoryIconPostRequest.label);
    memoryIconFormData.append('bookId', memoryIconPostRequest.bookId.toString());

    const response: AxiosResponse<MemoryIconPostResponse> = await this._instance.post(`/v1/users/${userId}/memoryIcons`, memoryIconFormData);
    return {
      id: response.data.icon.id,
      label: response.data.icon.label,
      bookId: response.data.icon.bookId,
      key: response.data.icon.key,
      userId: response.data.icon.userId,
      type: response.data.icon.type,
      url: response.data.icon.url,
    };
  }

  public async fetchMemoryIconsByUser(userId: number) : Promise<MemoryIcon[]> {
    const response: AxiosResponse<MemoryIconGetResponse> = await this._instance.get(`/v1/users/${userId}/memoryIcons`);
    return response.data.icons.map((memoryIcon: MemoryIcon) => ({
      id: memoryIcon.id,
      label: memoryIcon.label,
      bookId: memoryIcon.bookId,
      key: memoryIcon.key,
      userId: memoryIcon.userId,
      type: memoryIcon.type,
      url: memoryIcon.url,
    }));
  }

  public async fetchStoryBranchesByEpisodeId(episodeId: number): Promise<Branch[]> {
    const response = await this._instance.get(`/v1/stories/${episodeId}/branches`);
    return response.data.branches;
  }

  public async fetchBookEconomy(bookId: number | string, type = StoryState.Live): Promise<Book> {
    const response = await this._instance.get(`/v1/books/${bookId}/exportRaw/${type}`);
    return response.data.book as Book;
  }

  public async fetchUsedArtBooksByCharacterId(characterId: number): Promise<UsedArtBook[]> {
    const response = await this._instance.get(`/v1/customcharacters/checkdeletion/${characterId}`);
    return response.data.books ?? [];
  }

  public async fetchUsedArtBooksByBackgroundId(backgroundId: number): Promise<UsedArtBook[]> {
    const response = await this._instance.get(`/v1/backgrounds/checkdeletion/${backgroundId}`);
    return response.data.books ?? [];
  }

  public async deleteUsedArtBooksByCharacterId(imageId: number, force = false): Promise<void> {
    await this._instance.delete(`/v1/customcharacters/${imageId}${force ? '/true' : ''}`);
  }

  public async deleteUsedArtBooksByBackgroundId(imageId: number, force = false): Promise<void> {
    await this._instance.delete(`/v1/backgrounds/${imageId}${force ? '/true' : ''}`);
  }

  public async fetchUsedMemoryIdsInLiveEpisodesByBookId(bookId: number): Promise<number[]> {
    const response = await this._instance.get(`/v1/books/${bookId}/variablescheckusage`);
    return response.data.usage;
  }

  public async fetchAchievementsByBookId(bookId: number, abortController?: AbortController): Promise<IAchievementGetDTO[]> {
    const response = await this._instance.get(`/v1/achievements/get/${bookId}`, { signal: abortController?.signal });
    return response.data.achievements;
  }

  public async createAchievementByBookId(bookId: number, achievement: IAchievementPostDTO): Promise<IAchievementGetDTO> {
    const response = await this._instance.post(`/v1/achievements/create/${bookId}/`, achievement);
    return response.data.achievement;
  }

  public async updateAchievementByBookId(bookId: number, achievementId: number | string, achievement: IAchievementPostDTO): Promise<IAchievementGetDTO> {
    const response = await this._instance.post(`/v1/achievements/update/${bookId}/${achievementId}`, achievement);
    return response.data.achievement;
  }

  public async deleteAchievementByBookId(bookId: number, achievementId: number | string): Promise<void> {
    const response = await this._instance.delete(`/v1/achievements/delete/${bookId}/${achievementId}`);
    return response.data;
  }

  public async checkUsedAchievementById(achievementId: number): Promise<boolean> {
    const response = await this._instance.get(`/v1/achievements/records/used/${achievementId}`);
    return response.data.used;
  }

  public async createEpisodeNode(episodeId: number, node: any): Promise<Branch> {
    const response = await this._instance.post(`/v1/stories/${episodeId}/branches`, node);
    return response.data.branch;
  }

  public async updateEpisodeNode(episodeId: number, nodeId: number, node: Partial<Branch>): Promise<Branch> {
    const response = await this._instance.put(`/v1/stories/${episodeId}/branches/${nodeId}`, node);
    return response.data.branch;
  }

  public async fetchBookLocations(bookId: number): Promise<BookLocation[]> {
    const response = await this._instance.get(`/v1/books/${bookId}/locations`);
    return response.data.locations;
  }

  public async fetchPerformanceRanksByEpisodeId(episodeId: number): Promise<IPerformanceRankDTO[]> {
    const response = await this._instance.get(`/v1/stories/${episodeId}/performanceranks`);
    return response.data.performanceRanks;
  }

  public async postPerformanceRank(episodeId: number, performanceRank: IPerformanceRankDTO[]): Promise<IPerformanceRankDTO | null> {
    const postData: IPerformanceRankPostDTO[] = performanceRank.map((rank) => ({
      variableId: rank.variable.id,
      ranks: rank.ranks,
    }));
    const response = await this._instance.post(`/v1/stories/${episodeId}/performanceranks`, postData);
    return response.data.performanceRank;
  }

  public async updateAchievementOrderByBookId(bookId: number, order: number[]): Promise<void> {
    await this._instance.post(`/v1/achievements/order/${bookId}`, order);
  }
}
