import { GroupBase, StylesConfig } from 'react-select';
import { AvailableWhenOptions } from './AchievementWhenField';

export const customSelectStyles: StylesConfig<AvailableWhenOptions, false, GroupBase<AvailableWhenOptions>> = {
  menu: (provided) => ({
    ...provided,
    width: '400px',
    overflow: 'hidden',
  }),
  option: (base, state) => {
    const { isFocused, isSelected } = state;
    let backgroundColor = 'white';
    if (isFocused) {
      backgroundColor = '#ffb5a5';
    } else if (isSelected) {
      backgroundColor = '#ff6251';
    }

    return {
      ...base,
      marginLeft: '1rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'calc(100% - 1rem)',
      backgroundColor,
      '&:hover': {
        backgroundColor: '#ff6251',
        color: 'white',
      },
    };
  },
  groupHeading: (base) => ({
    ...base,
    fontWeight: 'bold',
    paddingLeft: '10px',
  }),
};
