import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Form, useFormikContext } from 'formik';
import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useAchievementsContext } from '../../../../contexts/AchievementsContext/AchievementsContext';
import { ConfirmModal } from '../../../ui/ConfirmModal/ConfirmModal';
import { IAchievementModal } from '../AchievementsModalTypes';
import { useAchievementsModalContext } from '../context/AchievementsModalContext';
import { AchievementRowSortable } from './AchievementRowSortable';
import { useConfirmDeleteModal } from './useConfirmDeleteModal';

export function AchievementsForm() {
  const {
    values, setValues, handleSubmit, isSubmitting,
  } = useFormikContext<IAchievementModal[]>();
  const { isFormLoading } = useAchievementsModalContext();
  const { isLoading: isAchievementsLoading } = useAchievementsContext();

  const isFiledSetDisabled = isSubmitting || isFormLoading || isAchievementsLoading;

  const {
    showConfirm,
    confirmDeleteAchievementId,
    handleDeleteConfirm,
    handleOnConfirm,
    handleOnCancel,
  } = useConfirmDeleteModal();

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(
    mouseSensor,
    touchSensor,
    keyboardSensor,
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setValues((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);
        return newItems;
      });
    }
  };

  return (
    <Modal.Body>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <Form onSubmit={handleSubmit}>
          <fieldset disabled={isFiledSetDisabled}>
            <SortableContext
              items={values}
              strategy={verticalListSortingStrategy}
            >
              {/* <DragOverlay modifiers={[restrictToWindowEdges]}> */}
              {values.map((achievement, index) => (
                <AchievementRowSortable
                  key={achievement.id}
                  id={achievement.id}
                  index={index}
                  confirmDelete={handleDeleteConfirm}
                />
              ))}
              {/* </DragOverlay> */}
            </SortableContext>
          </fieldset>
          <ConfirmModal
            centered
            show={showConfirm}
            isLoading={isFiledSetDisabled}
            title="Delete Achievement"
            onConfirm={handleOnConfirm}
            onCancel={handleOnCancel}
            className="overlay"
            backdrop="static"
          >
            <div className="text-center">
              Are you sure you want to delete this achievement?
              <br />
              <b>{values.find((v) => v.id === confirmDeleteAchievementId)?.name ?? 'unknown'}</b>
            </div>
          </ConfirmModal>
        </Form>
      </DndContext>
      {isFiledSetDisabled && (
      <div className="overlay">
        <div className="boxSpinner"><Spinner animation="border" variant="primary" /></div>
      </div>
      )}
    </Modal.Body>
  );
}
