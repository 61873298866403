import _ from 'lodash-es';

/**
 * A utility function that computes the deep difference between two objects of generic types using lodash.
 *
 * @param obj1 - The first object to compare.
 * @param obj2 - The second object to compare.
 * @returns An object representing the differences between obj1 and obj2.
 */
export function deepDiff<T>(obj1: T, obj2: T): Partial<T> {
  const changes = (obj: any, base: any): any => _.transform(obj, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      // eslint-disable-next-line no-param-reassign
      result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : { oldValue: base[key], newValue: value };
    }
  });

  return changes(obj2, obj1) as Partial<T>;
}
