import React from "react";
import { FaTiktok, FaInstagram, FaDiscord } from 'react-icons/fa';
import logoImage from '../../../assets/images/dorian-purple.png';
import xLogo from '../../../assets/images/x-logo.svg';
import styles from "./MaintenancePage.module.scss";

export function MaintenancePage() {
  history.pushState(null, '', '/');
  return (
    <>
      <div className={styles.maintenanceContainer}>
      <img src={logoImage} alt="Dorian" className={styles.maintenanceLogo} />
      <h1 className={styles.maintenanceMessage}>We're currently undergoing maintenance.</h1>
      <p className={styles.maintenanceSubmessage}>
        Please come back later or visit our{' '}
        <a href="https://home.dorian.live" className={styles.maintenanceLink}>
          home page
        </a>
        .
      </p>

      <div className={styles.socialContainer}>
        <a href="https://x.com/DorianStories" target="_blank" rel="noopener noreferrer" className={styles.iconButton}>
          <img src={xLogo} alt="X Logo" className={styles.xLogo} />
        </a>
        <a href="https://www.instagram.com/dorian.live" target="_blank" rel="noopener noreferrer" className={styles.iconButton}>
          <FaInstagram size={30} />
        </a>
        <a href="https://discord.gg/dorian" target="_blank" rel="noopener noreferrer" className={styles.iconButton}>
          <FaDiscord size={30} />
        </a>
        <a href="https://www.tiktok.com/@dorian.live" target="_blank" rel="noopener noreferrer" className={styles.iconButton}>
          <FaTiktok size={30} />
        </a>
      </div>
    </div>
    </>
  );
}

