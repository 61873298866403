import React from 'react';
import { Story } from 'dorian-shared/types/story/Story';
import { getEpisodeTitleIcons } from '../../utils';

type BonusEpisodeOptionsProps = {
  bonusEpisodes: Story[]
}

export function BonusEpisodeOptions(props: BonusEpisodeOptionsProps) {
  const { bonusEpisodes } = props;

  if (bonusEpisodes.length > 0) {
    return (
      <>
        <option value="">None</option>
        {bonusEpisodes.map((episode) => (
          <option
            key={episode.id}
            value={episode.uuid}
            title={episode.title}
          >
            {`${episode.title} ${getEpisodeTitleIcons(episode)} `}
          </option>
        ))}
      </>
    );
  }
  return (
    <option value="" disabled>None available</option>
  );
}
