import React from 'react';
import { Col, FormCheck, FormGroup } from 'react-bootstrap';
import cs from './LockedFormGroup.module.scss';

type LockedFormGroupProps = {
  value: boolean,
  onChange: (value: boolean) => void
  disabled?: boolean
}

export function LockedFormGroup(props: LockedFormGroupProps) {
  const { value, onChange, disabled } = props;

  return (
    <FormGroup as={Col} controlId="isLocked" className={cs.formGroup}>
      {/* We should use a hidden input to send the value to the server when disabled */}
      <input
        type="hidden"
        name="isLocked"
        value={value ? 'on' : 'off'}
      />
      <FormCheck
        custom
        id="isLocked-checkbox"
        disabled={disabled}
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        label="Locked"
      />

    </FormGroup>
  );
}
