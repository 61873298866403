import cs from 'classnames';
import React, {
  useState, ChangeEventHandler, FormEventHandler, useCallback, useEffect, useRef,
} from 'react';
import {
  Alert,
  Button, Col, Form, Modal, Row, Spinner, ProgressBar,
} from 'react-bootstrap';
import { v4 as uuidV4 } from 'uuid';
import { useApiService } from '../../../../../contexts/ApiServiceContext/ApiServiceContext';
import { useAsyncOperationState } from '../../../../../dorian-shared/hooks/useAsyncOperationState';
import { type AudioConfig } from '../../../../../dorian-shared/types/media/MediaConfigs';
import { convertAudio, downloadBlob } from '../../../../../services/ffmpeg';
import { api } from '../../../../api';
import { AudioPreview } from '../AudioPreview/AudioPreview';
import { useRefetchAudio } from '../AudiosProvider/AudiosProvider';
import classNames from './UploadAudio.module.scss';

const acceptFileTypes = '.mp3,.wav';
const MAX_FILE_SIZE = 50 * 1024 * 1024; // 100MB

const requestConfig = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export function UploadAudio({
  onUpload,
  onCancel,
}: {onUpload: VoidFunction, onCancel: VoidFunction}) {
  const abortController = useRef<AbortController | null>(null);
  const apiService = useApiService();
  const refetchAudio = useRefetchAudio();
  const [loading, setLoading] = useState(false);
  const [validatedForm, setValidatedForm] = useState(false);

  // audio
  const [isAudioConverting, setIsAudioConverting] = useState(false);
  const [audioConversionProgress, setAudioConversionProgress] = useState(0);
  const [convertedAudio, setConvertedAudio] = useState<Blob | null>(null);
  const [convertedAudioURL, setConvertedAudioURL] = useState<string | null>(null);

  const [audioTitle, setAudioTitle] = useState('');
  const [audioType, setAudioType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [audioConfig, setAudioConfig] = useState<AudioConfig | null>(null);

  const [,
    {
      isLoading,
      setToLoading,
      setToSuccess,
      setToError,
    },
  ] = useAsyncOperationState();
  const loadConfig = useCallback(async () => {
    setToLoading();
    try {
      const _audioConfig = (await apiService.fetchAudioConfig()) ?? {};
      setAudioConfig(_audioConfig);
      setToSuccess();
    } catch (e) {
      setToError();
    }
  }, [apiService, setToError, setToLoading, setToSuccess]);

  useEffect(() => {
    loadConfig();
  }, [loadConfig]);

  const tryPreprocessFile = async (file: File) => {
    if (!audioConfig) {
      return;
    }
    setIsAudioConverting(true);
    abortController.current = new AbortController();
    const _convertedAudio = await convertAudio(file, audioConfig, (progress) => {
      setAudioConversionProgress(Math.floor(progress * 100));
    }, abortController.current.signal);
    abortController.current = null;
    setConvertedAudio(_convertedAudio);
    setConvertedAudioURL(URL.createObjectURL(_convertedAudio));
    setIsAudioConverting(false);
  };

  useEffect(() => () => {
    abortController.current?.abort();
  }, []);

  const onDownloadConvertedAudio = () => {
    if (!convertedAudio) {
      return;
    }

    downloadBlob(convertedAudio, 'converted.mp3');
  };

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!convertedAudio) {
      return;
    }

    const form = event.currentTarget;
    if (!form.checkValidity()) {
      setValidatedForm(true);
      return;
    }
    const formData = new FormData();
    const alias = uuidV4();
    formData.append('audio', convertedAudio, `${alias}.mp3`);
    formData.append('alias', alias);
    formData.append('title', audioTitle);
    formData.append('type', audioType);

    setLoading(true);

    api.post('/v1/audio', formData, requestConfig)
      .then(() => {
        setLoading(false);
        refetchAudio();
        onUpload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const hideErrorMessage = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  // TODO: Ask for format and min/max size file
  const handleSelectFile: ChangeEventHandler<HTMLInputElement> = (event) => {
    hideErrorMessage();
    const file = event?.target?.files?.[0];

    if (!file) {
      setErrorMessage('File not selected.');
      return;
    }

    if (file.type.indexOf('mpeg') === -1 && file.type.indexOf('wav') === -1) {
      setErrorMessage('Only mp3 & wav formats allowed');
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage('Maximum file size is 5MB');
      return;
    }

    if (!audioTitle) {
      setAudioTitle(file.name.replace(/\.[^\\/.]+$/, ''));
    }

    tryPreprocessFile(file);
  };

  return (
    <Modal
      show
      size="lg"
    >
      <Form
        noValidate
        validated={validatedForm}
        className={classNames.form}
        onSubmit={handleFormSubmit}
      >
        <Modal.Header
          closeButton
          className={classNames.modalHeader}
        >
          <Modal.Title
            className={classNames.modalTitle}
          >
            Upload audio
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={classNames.modalBody}
        >
          <Row>
            <Form.Group
              as={Col}
              sm="12"
              controlId="audio-file"
              className={classNames.formGroup}
            >
              <Form.Label className={classNames.formLabel}>
                Audio File
              </Form.Label>
              <Form.Control
                required
                name="audio-file"
                type="file"
                placeholder="Image"
                accept={acceptFileTypes}
                onChange={handleSelectFile}
              />
              <Form.Control.Feedback type="invalid">
                Please choose an audio.
              </Form.Control.Feedback>
              {isAudioConverting && (
                <ProgressBar>
                  <ProgressBar
                    striped
                    variant="success"
                    now={audioConversionProgress}
                    label={`${audioConversionProgress}%`}
                  />
                </ProgressBar>
              )}
              {convertedAudioURL && (
                <AudioPreview
                  url={convertedAudioURL}
                />
              )}
            </Form.Group>

            <Form.Group as={Col} sm="8" controlId="audio-title">
              <Form.Label className={classNames.formLabel}>
                Title
              </Form.Label>
              <Form.Control
                required
                value={audioTitle}
                name="audio-title"
                type="text"
                size="sm"
                placeholder="Audio title"
                pattern="^([A-Za-z]|[0-9]|_|-|)+$"
                onChange={(event) => {
                  hideErrorMessage();
                  setAudioTitle(event.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please use characters A-Z,a-z,0-9,_,-
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm="4" controlId="audio-type">
              <Form.Label className={classNames.formLabel}>
                Type
              </Form.Label>
              <Form.Control
                required
                value={audioType}
                name="audio-type"
                as="select"
                type="select"
                size="sm"
                onChange={(event) => {
                  hideErrorMessage();
                  setAudioType(event.target.value);
                }}
              >
                <option value="">Select type</option>
                <option value="music">music</option>
                <option value="effect">effect</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select audio type
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              variant="success"
              disabled={!convertedAudio}
              className={classNames.button}
              onClick={onDownloadConvertedAudio}
            >
              Download
            </Button>

            <Button
              type="reset"
              variant="secondary"
              className={classNames.button}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
              disabled={isAudioConverting}
              className={classNames.button}
            >
              {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              )}
              Upload
            </Button>
          </div>
          {
            errorMessage && (
              <Col md={12}>
                <Alert variant="danger">
                  {errorMessage}
                </Alert>
              </Col>
            )
          }
        </Modal.Footer>
      </Form>
      {isLoading
        && (
          <>
            <div className={cs({ overlay: isLoading })} />
            <Spinner
              variant="primary"
              animation="border"
              className="spinner"
            />
          </>
        )}
    </Modal>
  );
}
