import React from 'react';
import styles from './GlobalErrorBoundary.module.scss';

class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (error.response?.status === 503) {
      window.location.href = '/maintenancepage';
    }
  }

  /* This shouldn't render unless something unexpected happened */
  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.errorContainer}>
          <p className={styles.errorMessage}>We're currently undergoing maintenance.</p>
          <p className={styles.suggestion}>Please try again later or visit our <a href="https://home.dorian.live" className={styles.homeLink}>home page</a>.</p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default GlobalErrorBoundary;
