import React, { PureComponent } from 'react';
import {
  Alert, Button, Col, Row,
} from 'react-bootstrap';
import imgEdit from '../../../assets/images/edit-branch.svg';
import { EpisodeValidationAction } from '../../../dorian-shared/types/validation/episodeValidation';
import { api } from '../../api';
import { Panel } from './Panel';
import './ValidatePanel.scss';

export class ValidatePanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      active: null,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    }, () => {
      this.loadData();
    });
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (show !== prevProps.show && show === true) {
      this.setState({
        loading: true,
        active: null,
      }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    const { storyId, errCount } = this.props;

    api.get(`/v1/stories/${storyId}/validate`)
      .then((res) => {
        const error = Number(res.data.data.filter((el) => el.type === 'error').length);
        const warning = Number(res.data.data.filter((el) => el.type === 'warning').length);
        errCount(warning, error);
        this.setState({
          data: res.data.data,
          loading: false,
        });
      });
  }

  render() {
    const {
      show, hideActionButtons, onHide, validateAction,
    } = this.props;
    const { loading, active, data: items } = this.state;

    if (!show) {
      return null;
    }

    return (
      <Panel
        pageTitle="Validate"
        show={show}
        onHide={onHide}
        loading={loading}
      >

        <div className="validatePanelContent">
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading === false
            ? items.length > 0
              ? items.map((item, i) => {
                const isActionAvailable = Object.values(EpisodeValidationAction).includes(item.key);
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Row key={i} className=" align-items-start">
                    <Col className="mr-0 pr-0">
                      <Alert
                        variant={item.type === 'error' ? 'danger' : item.type}
                        className={(active === i) || (active !== null && items[active].branchId && (items[active].branchId === item.branchId)) ? 'lastEdit' : null}
                        onClick={() => {
                          validateAction(item.branchId, null);
                          this.setState({
                            active: i,
                          });
                        }}
                      >
                        {item.msg}
                      </Alert>
                    </Col>
                    {!hideActionButtons && isActionAvailable
                      ? (
                        <Col sm={2}>
                          <Button
                            style={{ visibility: ['book'].includes(item.key) ? 'hidden' : 'visible' }}
                            size="sm"
                            block
                            variant="secondary"
                            onClick={() => {
                              validateAction(item.branchId, item.key);
                            }}
                          >
                            <img src={imgEdit} className="btnImg" alt="" />
                          </Button>
                        </Col>
                      )
                      : null}

                  </Row>
                );
              })
              : <Alert variant="light">No errors or warnings detected</Alert>
            : null}
        </div>

      </Panel>
    );
  }
}
