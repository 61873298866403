import { BookCreateLink } from '../pages/BookCreateLink';
import { PrivacyPolicyPage } from '../pages/Home/PrivacyPolicy';
import { CopyrightPolicyPage } from '../pages/Home/PrivacyPolicy/CopyrightPolicyPage';
import { TermsOfUsePage } from '../pages/Home/PrivacyPolicy/TermsOfUsePage';
import { WriteWithUs } from '../pages/Home/WriteWithUs';
import { ConfirmEmail } from '../pages/Logins/ConfirmEmail/ConfirmEmail';
import { ResetPassword } from '../pages/Logins/ResetPassword/ResetPassword';
import { SetPassword } from '../pages/Logins/SetPassword/SetPassword';
import { SignIn } from '../pages/Logins/SignIn/SignIn';
import { SignUp } from '../pages/Logins/SignUp/SignUp';
import { VerifiedEmail } from '../pages/Logins/VerifiedEmail/VerifiedEmail';
import { PageNotFound } from '../pages/PageNotFound';
import { PlayPreview } from '../pages/PlayPreview';
import { LandingPageRedirect } from './LandingPageRedirect';
import { MaintenancePage } from '../pages/MaintenancePage';

export const routesNotLogged = [
  {
    path: '/',
    exact: true,
    component: LandingPageRedirect,
  },
  {
    path: '/maintenancepage',
    component: MaintenancePage,
  },
  {
    path: '/home',
    component: LandingPageRedirect,
  },
  {
    path: '/play/:uuid',
    component: PlayPreview,
  },
  // Legacy. Leave for compatibility.
  {
    path: '/invite',
    search: '?token=id',
    component: WriteWithUs,
  },
  {
    path: '/sign-in',
    component: SignIn,
  },
  {
    path: '/sign-up',
    component: SignUp,
  },
  {
    path: '/verified-email',
    component: VerifiedEmail,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/set-password',
    component: SetPassword,
  },
  {
    path: '/confirm-email',
    component: ConfirmEmail,
  },
  {
    path: '/reset',
    component: SetPassword,
  },
  {
    path: '/setpassword',
    component: SetPassword,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/terms-of-use',
    component: TermsOfUsePage,
  },
  {
    path: '/copyright-policy',
    component: CopyrightPolicyPage,
  },
  // Legacy. Leave it for compatibility
  {
    path: '/write-with-us',
    component: SignUp,
  },
  // Legacy. Leave it for compatibility
  {
    path: '/write-with-us-app',
    component: SignUp,
  },
  {
    path: '/create/template/:id',
    component: BookCreateLink,
  },
  // Legacy. Leave it for compatibility
  {
    path: '/signup',
    component: SignUp,
  },
  {
    path: '**',
    component: PageNotFound,
  },
];
